import React from 'react'
import AboutTeam from '../Components/AboutTeam.js'
import MyNavbar from './Login/DemoNav.js';
import Footer from './Footer.js';

const About = () => {
  return (
    <div>
      <MyNavbar/>
      <AboutTeam />
      <div style={{ marginTop: '5px' }}>
  <Footer />
</div>
    </div>
  );
};

export default About;
