import React from 'react';
import { useState, useRef } from 'react';
import '../CSS/contact.css';
import emailjs from '@emailjs/browser';


const ContactUs = () => { 
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        message: ''
      });
      
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value
        });
      };
      const form = useRef();
      const handleSubmit = (e) => {
        e.preventDefault();
        emailjs.sendForm('service_6vkte2n', 'template_jg0og1a', form.current, 'eYrmRc34TOl5yz0UP')
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
        // console.log('Form data submitted:', formData);
        // e.target.submit();
      };

    return(
<div className="bg-image">
<div className="contact-info">
    <h3>Contact - Info (Development Officer) </h3>
                <p>Name: Sumed Mokhale</p>
                <p>Phone: +91 98234 08368</p>
            </div>
<div className="form">
<h1><b> Get in touch </b> </h1>
      <form
      ref={form} onSubmit={handleSubmit}> 
      {/* // onSubmit={handleSubmit} */}
        <input
          type="text"
          id="name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          placeholder="Enter your name"
          required
        />
     
        <input
          type="email"
          id="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          placeholder="Enter your email"
          required
        />

        <input
          type="tel"
          id="phone"
          name="phone"
          value={formData.phone}
          onChange={handleChange}
          placeholder="Enter your phone number"
          required
        />


        <textarea
          id="message"
          name="message"
          value={formData.message}
          onChange={handleChange}
          placeholder="Enter your message"
          required
        ></textarea>

        <button type="submit">Submit</button>
      </form>
   </div>
   
</div>
          );
    };
export default ContactUs;