import React from 'react';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import '../../CSS/AdminNavbar.css';

const AdminNavbar = () => {
  
  return (
    <Navbar className="admin-navbar" bg="light" expand="lg">
      <Navbar.Brand href="/main-page">Admin Panel</Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
        </Nav>
        <Nav className="ml-auto">
          <NavDropdown title="Admin User" id="basic-nav-dropdown">
            <NavDropdown.Item href="/main-page">Profile</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item className="logout-button" href="/Login">Logout</NavDropdown.Item>
          </NavDropdown>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default AdminNavbar;
