import React, { useState, useEffect } from 'react';
import { getDatabase, ref, onValue } from 'firebase/database';
import DemoSidebar from './DemoSidebar';
import AdminNavbar from './AdminNavbar';
import styles from '../../CSS/NewAgents.css';

const NewAgents = () => {
  const [agents, setAgents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = () => {
      const agentsRef = ref(getDatabase(), 'agents');

      onValue(agentsRef, (snapshot) => {
        if (snapshot.exists()) {
          const agentsArray = Object.entries(snapshot.val())
            .map(([id, data]) => ({ id, ...data }))
            .sort((a, b) => b.timestamp - a.timestamp); 

          setAgents(agentsArray);
        } else {
          setAgents([]);
        }
        setLoading(false);
      }, (error) => {
        console.error('Error fetching data:', error);
        setError(error.message || 'Error fetching data');
        setLoading(false);
      });
    };

    fetchData();
  }, []);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  return (
    <div>
        <AdminNavbar/>
        <DemoSidebar/>
      <h2 className='new-agents-heading'>Registered Agents</h2>
      <table className='new-agents-table'>
        <thead>
          <tr>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Email</th>
            <th>Mobile</th>
            <th>Date of Birth</th>
            <th>Job Title</th>
            <th>Address</th>
            <th>City</th>
            <th>Date of Registration</th>
          </tr>
        </thead>
        <tbody>
          {agents.map((agent) => (
            <tr key={agent.id}>
              <td>{agent.firstName}</td>
              <td>{agent.lastName}</td>
              <td>{agent.email}</td>
              <td>{agent.mobile}</td>
              <td>{agent.dob}</td>
              <td>{agent.jobTitle}</td>
              <td>{agent.address}</td>
              <td>{agent.city}</td>
              <td>{new Date(agent.timestamp).toLocaleString()}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default NewAgents;
