import React, { useState } from 'react';
import '../../CSS/Login.css';
import { useNavigate } from 'react-router-dom';
import { getAuth, sendPasswordResetEmail, fetchSignInMethodsForEmail } from 'firebase/auth';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MyNavbar from './DemoNav';



const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const auth = getAuth();

 
  const handleLogin = async () => {
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Log the user data
      console.log('User authenticated:', user.email, user.uid);

      // Show toast message
      toast.success('Login successful!', {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
      });

      // Redirect to the dashboard or another page
      navigate('/main-page');  // <-- Redirect to /main-page after successful login
    } catch (error) {
      // Handle login errors
      console.error('Login error:', error.message);

      // Show toast message for login failure
      toast.error('Login failed. Please check your credentials.', {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    }
  };

 
const handleForgotPassword = async () => {
  try {
    // Check if the user with the provided email exists
    const signInMethods = await fetchSignInMethodsForEmail(auth, email);

    // If the user exists, send a password reset email
    if (signInMethods && signInMethods.length > 0) {
      await sendPasswordResetEmail(auth, email);

      // Show toast message
      toast.success('Password reset email sent! Check your email for instructions.', {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 5000,
      });
    } else {
      // User not found
      toast.error('No account found with this email address.', {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    }
  } catch (error) {
    // Handle errors related to sending the password reset email
    console.error('Forgot password error:', error.message);

    // Show a generic error message for other errors
    toast.error('Error sending password reset email. Please try again.', {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 3000,
    });
  }
};

  return (
    <div>
      <MyNavbar />
      <div className="login-container">
        <h1>Login</h1>
        <label>Email:</label>
        <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
        <label>Password:</label>
        <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
        {/* <div className="forgot-password">
          <span onClick={handleForgotPassword}>Forgot Password?</span>
        </div> */}
        <button onClick={handleLogin}>Login</button>
      </div>

    </div>
  );
};

export default Login;
