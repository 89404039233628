import { initializeApp } from 'firebase/app';
import { getDatabase, serverTimestamp } from 'firebase/database';
import { getAuth, RecaptchaVerifier } from 'firebase/auth';
import { getAnalytics } from 'firebase/analytics';
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCjFxKONw0fQpH3OcF7suqrSksW66M_e_I",
  authDomain: "lic-4c048.firebaseapp.com",
  databaseURL: "https://lic-4c048-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "lic-4c048",
  storageBucket: "lic-4c048.appspot.com",
  messagingSenderId: "51042161065",
  appId: "1:51042161065:web:d835b921191c9772d6f4b8",
  measurementId: "G-Y82KD8Z1P3"
};

const app = initializeApp(firebaseConfig);
const db = getDatabase(app);
const auth = getAuth(app);
const analytics = getAnalytics(app);

export { db, serverTimestamp, auth, RecaptchaVerifier, firebaseConfig };