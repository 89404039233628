import React, { useState, useEffect } from 'react';
import { getDatabase, ref, onValue } from 'firebase/database';
import '../../CSS/ContactAdmin.css';
import AdminNavbar from './AdminNavbar';
import DemoSidebar from './DemoSidebar';

const db = getDatabase();

const ContactAdmin = () => {
  const [contacts, setContacts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchContacts = () => {
      const contactsRef = ref(db, 'contact_people');

      onValue(contactsRef, (snapshot) => {
        if (snapshot.exists()) {
          const contactsArray = Object.entries(snapshot.val()).map(([id, data]) => ({ id, ...data }));
          setContacts(contactsArray);
        } else {
          setContacts([]);
        }
        setLoading(false);
      }, (error) => {
        console.error('Error fetching contacts:', error);
        setError(error.message || 'Error fetching contacts');
        setLoading(false);
      });
    };

    fetchContacts();
  }, []);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  return (
    <div>
        <AdminNavbar/>
        <DemoSidebar/>
      <div className="contact-admin-container container mt-4">
        <h2>Contact List</h2>
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>Name</th>
              <th>Mobile</th>
            </tr>
          </thead>
          <tbody>
            {contacts.map((contact) => (
              <tr key={contact.id}>
                <td>{contact.name}</td>
                <td>{contact.mobileNumber}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ContactAdmin;
