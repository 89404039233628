// Import necessary modules
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../CSS/DemoNav.css';
import { Navbar, Nav } from 'react-bootstrap';
import logo_lic from '../../images/logo_lic.jpeg';
import team_logo from '../../images/team_logo.jpg';
import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom';

const MyNavbar = () => {
  return (
    <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
      <Navbar.Brand as={Link} to="/">
      <img
          src={logo_lic}
          alt="LIC Logo"
          width="30"
          height="30"
          className="d-inline-block align-top"
        />{' '}
          {/* <img
          src={team_logo} // Use the second logo here
          alt="Another Logo"
          width="150"
          height="100"
          className="d-inline-block align-top"
        /> */}
        {/* TEAM AMAZING */}
      </Navbar.Brand>
      <Navbar.Brand>
          <img
            src={team_logo} 
            alt="Team Amazing Logo"
            width="500" 
            height="500" 
            className="team-logo d-inline-block align-top"
          />
        </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="mr-auto">
          <Nav.Link as={Link} to="/">Home</Nav.Link>
          <Nav.Link as={Link} to="/NeedServices">Need Services</Nav.Link>

          <Nav.Link as={Link} to="/About">About TEAM</Nav.Link>
          <Nav.Link as={Link} to="/Contact">Contact</Nav.Link>
          <Nav.Link as={Link} to="/Login">Login</Nav.Link>
          <Nav.Link as = {Link} to="/Register">Register</Nav.Link>
        </Nav>
        
      </Navbar.Collapse>
    </Navbar>
  );
};


export default MyNavbar;
