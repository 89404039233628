import React from 'react';
import DemoSidebar from './DemoSidebar';
import AdminNavbar from './AdminNavbar';
import '../../CSS/AdminNavbar.css';
import welcome_admin from '../../images/welcom_admin.jpg';

const MainPage = () => {
  return (
    <div>
      <AdminNavbar />
      <DemoSidebar />

      <div className="main-content">
        <div className="welcome-image-container">
          <img src={welcome_admin} alt="Welcome" style={{ width: '100%', height: 'auto' }} />
        </div>
      </div>
    </div>
  );
};

export default MainPage;
