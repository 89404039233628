import React, { useState } from 'react';
import '../CSS/RegisterForm.css';
import './firebase';
import { ref, push, set } from 'firebase/database';
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { db, serverTimestamp } from './firebase';
import { Link } from 'react-router-dom';
import lic from '../images/register/lic.jpg';
import MyNavbar from './Login/DemoNav';
import Footer from './Footer';

const OldPolicies = () => {
  const navigate = useNavigate();

  const bodyStyle = {
    backgroundImage: `url(${lic})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    height: 'auto', 
    margin: 0,  
    display: 'flex', 
    justifyContent: 'center', 
    alignItems: 'center',     
  };

  const formContainerStyle = {
    backgroundColor: 'white', 
    padding: '20px', 
  };


  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    mobile: '',
    policyNumber: '',
    city: '',
  });

  const [errors, setErrors] = useState({});
  const [isSubmitting, setSubmitting] = useState(false);


    const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Perform validation
    const newErrors = validateForm(formData);
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    setSubmitting(true);

    try {
      // Save form data to Firebase Realtime Database
      const databaseRef = ref(db, 'old_policies');
      const newPolicyRef = push(databaseRef);
      await set(newPolicyRef, {
        firstName: formData.firstName,
        lastName: formData.lastName,
        mobile: formData.mobile,
        policyNumber: formData.policyNumber,
        city: formData.city,
        timestamp: serverTimestamp(),
      });

      // Reset form data and setSubmitting after successful submission
      setFormData({
        firstName: '',
        lastName: '',
        mobile: '',
        policyNumber:'',
        city: '',
      });
      setSubmitting(false);

      // Optionally, you can navigate the user to another page after successful submission
      navigate('/success');
    } catch (error) {
      console.error('Error saving data to Firebase:', error);
      // Handle the error (e.g., show an error message to the user)
      setSubmitting(false);
    }
  };

  
  const validateForm = (data) => {
    let errors = {};

    // Mobile number validation
    const mobileRegex = /^[0-9]{10}$/;
    if (!mobileRegex.test(data.mobile)) {
      errors.mobile = 'Invalid mobile number';
    }

    // Name validation (allow only letters and spaces)
    const nameRegex = /^[A-Za-z\s]+$/;
    if (!nameRegex.test(data.firstName) || !nameRegex.test(data.lastName)) {
      errors.name = 'Invalid name';
    }

    // Policy number validation (allow only alphanumeric characters)
    const policyNumberRegex = /^[0-9a-zA-Z]+$/;
    if (!policyNumberRegex.test(data.policyNumber)) {
      errors.policyNumber = 'Invalid policy number';
    }

    // City validation (allow only letters and spaces)
    const cityRegex = /^[A-Za-z\s]+$/;
    if (!cityRegex.test(data.city)) {
      errors.city = 'Invalid city';
    }

    return errors;
  };

  

  return (
    <>
    <MyNavbar/>
    <body style={bodyStyle}>
      <div className="form-container" style={formContainerStyle}>
      <h2>Existing Policy</h2>
      
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="firstName">First Name:</label>
          <input
            type="text"
            id="firstName"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            placeholder="Enter your first name"
            required
          />
          {errors.name && <span className="error">{errors.name}</span>}
        </div>

        <div className="form-group">
          <label htmlFor="lastName">Last Name:</label>
          <input
            type="text"
            id="lastName"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            placeholder="Enter your last name"
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="mobile">Mobile Number:</label>
          <input
            type="text"
            name="mobile"
            value={formData.mobile}
            onChange={handleChange}
            placeholder="Enter your mobile number"
            required
          />
          {errors.mobile && <span className="error">{errors.mobile}</span>}
        </div>

        <div className="form-group">
          <label htmlFor="policyNumber">Policy Number:</label>
          <input
            type="text"
            name="policyNumber"
            value={formData.policyNumber}
            onChange={handleChange}
            placeholder="Enter your policy number"
            required
          />
          {errors.policyNumber && <span className="error">{errors.policyNumber}</span>}
        </div>

        <div className="form-group">
          <label htmlFor="city">City:</label>
          <input
            type="text"
            id="city"
            name="city"
            value={formData.city}
            onChange={handleChange}
            placeholder="Enter your City"
            required
          />
          {errors.city && <span className="error">{errors.city}</span>}
        </div>


        <button type="submit" disabled={isSubmitting}>
          {isSubmitting ? 'Submitting...' : 'Submit'}
        </button>
      </form>

     </div>
     </body>
     <div style={{ marginTop: '5px' }}>
        <Footer />
      </div>
     </>
  );
};


export default OldPolicies;
