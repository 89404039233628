import React, { useState } from 'react';
import '../CSS/RegisterForm.css';
import DatePicker from 'react-datepicker';
import './firebase';
import 'react-datepicker/dist/react-datepicker.css';
import { ref, push,set } from 'firebase/database';
import { getAuth } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { db, serverTimestamp } from './firebase';
import MyNavbar from './Login/DemoNav';
import Footer from './Footer';

const Register = () => {
  const navigate = useNavigate();
  const auth = getAuth();

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    mobile: '',
    dob: '',
    address: '',
    city: '',
  });

  const [errors, setErrors] = useState({});
  const [isSubmitting, setSubmitting] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
 

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Perform validation
    const newErrors = validateForm(formData);
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    try {
      // Store user data in the Realtime Database under "agents" node
      const agentsRef = ref(db, 'agents');
      const newAgentRef = push(agentsRef);

      // Create an object with user data to store in the database
      const agentData = {
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        mobile: formData.mobile,
        dob: formData.dob.toString(),
        address: formData.address,
        city: formData.city,
        timestamp: serverTimestamp(),
      };

      // Set the data in the Realtime Database under the pushed key
      await set(newAgentRef, agentData);

      // Navigate to another page or perform other actions after successful registration
      navigate('/success'); // Redirect to a success page, for example
    } catch (error) {
      // Handle registration errors
      console.error('Registration error:', error.message);
    }
  };

  const validateForm = (data) => {
    let errors = {};
    // Email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(data.email)) {
      errors.email = 'Invalid email address';
    }

    // Mobile number validation
    const mobileRegex = /^[0-9]{10}$/;
    if (!mobileRegex.test(data.mobile)) {
      errors.mobile = 'Invalid mobile number';
    }

    return errors;
  };

  
  return (
    <>
    <MyNavbar/>
    <div className="form-container">
      <h2>New Agent Registration Form</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="firstName">First Name:</label>
          <input
            type="text"
            id="firstName"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="lastName">Last Name:</label>
          <input
            type="text"
            id="lastName"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="email">Email:</label>
          <input
            type="text"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
          />
          {errors.email && <span className="error">{errors.email}</span>}
        </div>

        <div className="form-group">
          <label htmlFor="mobile">Mobile Number:</label>
          <input
            type="text"
            name="mobile"
            value={formData.mobile}
            onChange={handleChange}
          />
          {errors.mobile && <span className="error">{errors.mobile}</span>}
        </div>

        <div className="form-group">
  <label htmlFor="dob">Date of Birth:</label>
  <DatePicker
    selected={formData.dob}
    onChange={(date) => setFormData({ ...formData, dob: date })}
    dateFormat="MM/dd/yyyy"
    showYearDropdown
    scrollableYearDropdown
    yearDropdownItemNumber={15}
    maxDate={new Date()}
  />
</div>



        <div className="form-group">
          <label htmlFor="address">Address:</label>
          <input
            type="text"
            id="address"
            name="address"
            value={formData.address}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="city">City:</label>
          <input
            type="text"
            id="city"
            name="city"
            value={formData.city}
            onChange={handleChange}
          />
        </div>

        <button type="submit" disabled={isSubmitting}>
          {isSubmitting ? 'Submitting...' : 'Register'}
        </button>
      </form>

    </div>
    <div style={{ marginTop: '5px' }}>
        <Footer />
      </div>
      </>
 
  );
};

export default Register;
