import React from 'react';
import payment from '../images/register/payment.jpeg';
import MyNavbar from './Login/DemoNav';
import Footer from './Footer';

const PayPremium = () => {
  const containerStyle = {
    border: '2px solid #ccc', 
    padding: '20px', 
    borderRadius: '10px', 
    textAlign: 'center', 
    width: '50%', 
    margin: 'auto',
    marginTop: '150px', 
    backgroundColor: 'rgba(255, 255, 255, 0.8)', 
    backdropFilter: 'blur(10px)', 
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)', 
  };

  const noteStyle = {
    color: 'red', 
    marginTop: '10px', 
  };

  const pageStyle = {
    backgroundImage: `url(${payment})`, 
    backgroundSize: 'cover', 
    height: '100vh', 
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  return (
    <>
    <MyNavbar/>
    <div style={pageStyle}>
      <div style={containerStyle}>
        
        <h2>
          You can pay your premium by visiting the following link:
          <br />
          <a href="https://ebiz.licindia.in/D2CPM/#DirectPay" target="_blank" rel="noopener noreferrer">
            https://ebiz.licindia.in/D2CPM/#DirectPay
          </a>
        </h2>
        <div style={noteStyle}>
          <p>Note: This is the official website link of LIC to pay premium.</p>
        </div>
      </div>
      
    </div>
    <div style={{ marginTop: '5px' }}>
        <Footer />
      </div>
    </>
  );
};

export default PayPremium;
