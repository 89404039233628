import React, { useState, useEffect } from 'react';
import { getDatabase, ref, onValue } from 'firebase/database';
import { firebaseConfig } from '../firebase';
import '../../CSS/NewPolicyAdmin.css';
import AdminNavbar from './AdminNavbar';
import DemoSidebar from './DemoSidebar';

const db = getDatabase();

const NewPolicyAdmin = () => {
  const [newPolicies, setNewPolicies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = () => {
      const dataRef = ref(db, 'new_policies');

      onValue(dataRef, (snapshot) => {
        if (snapshot.exists()) {
          const policiesArray = Object.entries(snapshot.val()).map(([id, data]) => ({ id, ...data }));
          setNewPolicies(policiesArray);
        } else {
          setNewPolicies([]);
        }
        setLoading(false);
      }, (error) => {
        console.error('Error fetching data:', error);
        setError(error.message || 'Error fetching data');
        setLoading(false);
      });
    };

    fetchData();
  }, []);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }
  
  return (
    <div>
      <AdminNavbar/>
      <DemoSidebar />
      <div className="new-policy-container">
        <h2>New Policies</h2>
        <table className="new-policy-table" border="1">
          <thead>
            <tr>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Mobile</th>
              <th>City</th>
            </tr>
          </thead>
          <tbody>
            {newPolicies.map((policy) => (
              <tr key={policy.id}>
                <td>{policy.firstName}</td>
                <td>{policy.lastName}</td>
                <td>{policy.mobile}</td>
                <td>{policy.city}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default NewPolicyAdmin;
