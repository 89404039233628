import React from 'react';
import FlipCard from './FlipCard';
import '../CSS/Services.css';

const Services = () => {
  return (

    <div className="dash-container">
      <div className="row row-cols-1 row-cols-md-2 g-4">
        <div className="col">
          <div className="card">
          <FlipCard
           image="https://www.policyplanner.com/images/lic-insurance.png"
           title="Need New Policies"
           buttonText="Click here"
           linkTo="/new-policies" 
         />
          </div>
        </div>
        <div className="col mb-4">
          <div className="card">
            <FlipCard
            image="https://thumbs.dreamstime.com/b/policy-icon-white-background-simple-element-illustration-strategy-concept-isolated-editable-logo-symbol-design-can-be-use-141672950.jpg"
            title="Need Agent for Old Policies"
            buttonText="Click here"
            linkTo="/old-policies" 
          />
          </div>
        </div>
      </div>
      <div className="row row-cols-1 row-cols-md-2 g-4 mb-4">
        <div className="col">
          <div className="card">
          
            <FlipCard
              image="https://cdn2.iconfinder.com/data/icons/business-rounded-2/512/xxx009-1024.png"
              title="Pay Premium"
              buttonText="Click here"
              linkTo="/pay-premium" 
            />
          </div>
        </div>
      <div className="col mb-4">
        <div className="card">
          <FlipCard
            image="https://cdn2.iconfinder.com/data/icons/color-svg-vector-icons-2/512/chat_talk_voice_bubble_phone-1024.png"
            title="Talk to LIC"
            buttonText="Click here"
            linkTo="/talk-to-lic"
          />
        </div>
      </div>
    </div>
  </div>
  );
};

export default Services;
