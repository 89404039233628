import React, { useState, useEffect } from 'react';
import one from '../images/register/1.jpg';
import two from '../images/register/2.jpg';
import three from '../images/register/3.jpg';


const HomeCarousel = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleInterval = () => {
    setActiveIndex((prevIndex) => (prevIndex + 1) % 3);
  };

    useEffect(() => {
    const interval = setInterval(handleInterval, 5000); 

    
    return () => clearInterval(interval);
  }, []);

  const handleSlideChange = (index) => {
    setActiveIndex(index);
  };

  return (
    <div id="carouselExampleIndicators" className="carousel slide">
      <div className="carousel-indicators">
        <button
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide-to="0"
          className={activeIndex === 0 ? 'active' : ''}
          onClick={() => handleSlideChange(0)}
          aria-current="true"
          aria-label="Slide 1"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide-to="1"
          className={activeIndex === 1 ? 'active' : ''}
          onClick={() => handleSlideChange(1)}
          aria-label="Slide 2"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide-to="2"
          className={activeIndex === 2 ? 'active' : ''}
          onClick={() => handleSlideChange(2)}
          aria-label="Slide 3"
        ></button>
      </div>
      <div className="carousel-inner">
        <div className={`carousel-item ${activeIndex === 0 ? 'active' : ''}`}>
          <img src={one} className="d-block w-100" alt="Slide 1" />
        </div>
        <div className={`carousel-item ${activeIndex === 1 ? 'active' : ''}`}>
          <img src={two} className="d-block w-100" alt="Slide 2" />
        </div>
        <div className={`carousel-item ${activeIndex === 2 ? 'active' : ''}`}>
          <img src={three} className="d-block w-100" alt="Slide 3" />
        </div>
      </div>
      <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev" onClick={() => handleSlideChange((activeIndex + 2) % 3)}>
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next" onClick={() => handleSlideChange((activeIndex + 1) % 3)}>
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
      </button>
    </div>
  );
};

export default HomeCarousel;
