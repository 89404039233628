import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import '../CSS/Gallery.css'
import MyNavbar from './Login/DemoNav';
import Footer from './Footer';
import '../images/gallery/pic1.jpg';
import '../images/gallery/pic2.jpg';
import '../images/gallery/pic3.jpg';
import '../images/gallery/pic4.jpg';


const Gallery = () => {
  return (
    <>
      <MyNavbar />
      <Container fluid>
        <h1> PHOTO GALLERY - TEAM AMAZING FAMILY </h1>
        <Row>
          <Col xs={12} md={6} lg={6}>
            {/* Photo 1 */}
            <img src={"images/gallery/pic1.jpg"} alt="Photo 1" className="img-fluid" />
          </Col>

          <Col xs={12} md={6} lg={6}>
            {/* Photo 2 */}
            <img src={"images/gallery/pic2.jpg"} alt="Photo 2" className="img-fluid" />
          </Col>
        </Row>

        <Row>
          <Col xs={12} md={6} lg={6}>
            {/* Photo 3 */}
            <img src={"images/gallery/pic3.jpg"} alt="Photo 3" className="img-fluid" />
          </Col>

          <Col xs={12} md={6} lg={6}>
            {/* Photo 4 */}
            <img src={"images/gallery/picx4.jpg"} alt="Photo 4" className="img-fluid" />
          </Col>
        </Row>
      </Container>
      {/* <Footer /> */}
    </>
  );
};

export default Gallery;
