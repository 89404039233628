// Details.js

import React from 'react';
import '../CSS/Details.css';
import MyNavbar from './Login/DemoNav';
import Footer from './Footer';


const Details = () => {
  return (
    <div>
      <MyNavbar/>
    <div className="details-container">
      <h1 className="center-text heading" style={{ color: '#DD2222', textDecoration: 'underline' }}>FOLLOW THE BELOW STEPS TO PROCESS FURTHER</h1>
      <div className="steps">
        <p className="bold-text">01. Fill the Registration Form <br/>
        <p className="normal-text">Click here to <a href="/Register" style={{ color: '#00529C' }}>Register</a></p>
        </p>
        <p className="bold-text">02. Submit Necessary Documents:</p>
        <ul>
          <li>Kindly provide a photocopy of these documents to our Development officer.</li>
          <li>Documents Required:</li>
          <ul>
            <li>4 Colour Photos</li>
            <li>Aadhar card</li>
            <li>SSC or HSC Marksheet or passing certificate</li>
            <li>Pan Card</li>
            <li>Address Proof</li>
            <li>Bank account passbook or Cancelled Cheque</li>
          </ul>
        </ul>
        <p className="bold-text">03. Pay basic fees:</p>
        <ul>
          <li>150₹ [Registration fees]</li>
          <li>150₹ [Online Training]</li>
          <li>500₹ [IRDA Online Exam Fees]</li>
        </ul>
        <p className="bold-text">04. Offline OR Online training for 25 Hours:</p>
        <ul>
          <li>Our Development officer will provide you with the necessary training</li>
          <li>Training will be held offline or online as per your convenience</li>
          <li>You will receive a Training certificate from LIC after completion of 25 hours training</li>
        </ul>
        <p className="bold-text">05. IRDA Online Exam of 50 Marks:</p>
        <ul>
          <li>After successful completion of training, You need to give an online examination conducted by the insurance regulatory and development authority (IRDA)</li>
          <li>You will clear the exam after securing the required passing marks – 17/50</li>
        </ul>
        <p className="bold-text">06. Get your LIC Agent License!</p>
        <ul>
          <li>After successful completion of the examination, you will be awarded an Appointment Letter by the IRDA to work as an insurance agent.</li>
          <li>You will be appointed as a LIC Agent!</li>
        </ul>
      </div>
    </div>
    <p className="click-reg-text">Click here to <a href="/Register" style={{ color: '#00529C' }}>Register</a></p>
      <div style={{ marginTop: '5px' }}>
  <Footer />
</div>
    </div>
  );
}

export default Details;
