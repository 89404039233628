import { useState } from 'react';
import { ref, push, set, serverTimestamp } from 'firebase/database';
import 'bootstrap/dist/css/bootstrap.min.css';
import {db} from './firebase'; 
import talk from '../images/register/talk.webp';
import MyNavbar from './Login/DemoNav';
import Footer from './Footer';

const TalktoLIC = () => {
  const [formData, setFormData] = useState({
    name: '',
    mobileNumber: '',
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const validateForm = (data) => {
    let errors = {};
    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Perform validation
    const newErrors = validateForm(formData);
    if (Object.keys(newErrors).length > 0) {
      // Handle validation errors as needed
      return;
    }

    // Save form data to Firebase Realtime Database
    const databaseRef = ref(db, 'contact_people');
    const newRef = push(databaseRef);

    try {
      await set(newRef, {
        ...formData,
        timestamp: serverTimestamp(),
      });

      // Clear form data after successful submission
      setFormData({
        name: '',
        mobileNumber: '',
      });

      alert('Form submitted successfully!');
    } catch (error) {
      console.error('Error saving data to Firebase:', error);
 
    }
  };

  const containerStyle = {
    backgroundColor: 'white',
    border: '1px solid #ddd', 
    padding: '20px',
  };

  const bodyStyle = {
    backgroundImage: `url(${talk})`,  
    backgroundSize: 'contain',
    height: '100vh',
    margin: 0,
    textAlign: 'left',
  };

  const headingStyle = {
    color: 'white',
    marginTop: '10px',
    marginBottom: '20px',
  };

  return (
    <>
    <MyNavbar/>
    <div style={bodyStyle}>
     <h1 style={headingStyle}>Enter Your Details We will contact you soon...</h1>
      <div className="container mt-5" style={containerStyle}>
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <label htmlFor="name" className="form-label">
              Name:
            </label>
            <input
              type="text"
              className="form-control"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>
          <div className="mb-3">
            <label htmlFor="mobileNumber" className="form-label">
              Mobile Number:
            </label>
            <input
              type="text"
              className="form-control"
              id="mobileNumber"
              name="mobileNumber"
              value={formData.mobileNumber}
              onChange={handleChange}
              required
            />
          </div>
          <button type="submit" className="btn btn-primary">
            Submit
          </button>
        </form>
      </div>
    </div>
    <div style={{ marginTop: '5px' }}>
        <Footer />
      </div>
      </>
  );
};

export default TalktoLIC;
