// DemoSidebar.js
import React from 'react';
import '../../CSS/DemoSidebar.css';
// import './Dash';
import { Link } from 'react-router-dom';
import 'bootstrap-icons/font/bootstrap-icons.css';


const DemoSidebar = () => {
  return (
    <div>
      <button className="btn btn-primary" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasTop" aria-controls="offcanvasTop">
        Click Here !!
      </button>

      <div className="offcanvas offcanvas-start" tabIndex="-1" id="offcanvasTop" aria-labelledby="offcanvasTopLabel">
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="offcanvasTopLabel">
            Admin Panel
          </h5>
          <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div className="offcanvas-body">
       <Link to="/main-page">  <button><i className="bi bi-house-door-fill"></i> Home</button> </Link>
       <Link to="/new-policy-contact">  <button><i className="bi bi-window-dash"></i> New Policy</button> </Link>
       <Link to="/old-policy-contact">  <button><i className="bi bi-window-dash"></i> Existing Policy</button> </Link>
       <Link to="/admin-contact">  <button><i className="bi bi-window-dash"></i> Contact People for Service</button> </Link>
       <Link to="/new-agent">  <button><i className="bi bi-file-person"></i> New Agent Registered</button> </Link>
       <Link to="/Login"> <button className="logout-button"><i className="bi bi-box-arrow-right"></i> LOGOUT</button> </Link>
      
        </div>
      </div>
    </div>
  );
};

export default DemoSidebar;
