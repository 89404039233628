import React from 'react';
import '../CSS/aboutteam.css';
const AboutTeam = () => { 
    return(
<div className="background-image">

<div className="content">
    <h1> About Team</h1><br></br>
    <p> <b>Every day we wake up to the fact that more than 250 million lives are part of our family called Life Insurance Corporation.

We are humbled by the magnitude of the responsibility we carry and realise the lives that are associated with us are very valuable indeed.

Though this journey started over six decades ago, we are still conscious of the fact that, while insurance may be a business for us, being part of millions of lives every day for the past 65 years has been a process called TRUST.

A true saga Of Trust.</b></p>
</div>
</div>
          );
    };
export default AboutTeam;