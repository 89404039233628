// Footer.js

import React from 'react';
import { Link } from 'react-router-dom';
import '../CSS/Footer.css';
import logo_lic from '../images/logo_lic.jpeg';
import team_logo from '../images/team_logo.jpg';

const Footer = () => {
    return (
      <div className="footer" style={{ backgroundColor: '#333', color: '#fff', padding: '20px' }}>
        <div className="footer-section lic-section">
          <h2>LIC</h2>
          <p>Providing trusted insurance services for over 60 years.</p>
          <p>Team Amazing - Group of Professional Financial Advisors.</p>
        </div>
        <div className="footer-section about-section">
          <h3>About</h3>
          <Link to="/About">About Us</Link>
          <Link to="/Contact">Contact Us</Link>
          
        </div>
        <div className="footer-section explore-section">
          <h3>Explore</h3>
          <Link to="/">Home</Link>
          <Link to="/Gallery">Gallery</Link>
          <Link to="/NeedServices">Services</Link>
        </div>
        {/* <div className="footer-section social-section">
          <h3>Social</h3>
          <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">Facebook</a>
          <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">Instagram</a>
          <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer">Twitter</a>
        </div> */}


    <div className="footer-team-logo">
      <img src={team_logo} alt="Team Logo" className='footer-team-logo' />

      </div>

      <div className="footer-logo">
      <img src={logo_lic} alt="LIC Logo" className='footer-lic-logo' />

      </div>

      
      
    </div>
  );
};

export default Footer;
