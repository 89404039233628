import React from 'react'
import ContactUs from '../Components/ContactUs.js'
import MyNavbar from './Login/DemoNav.js';
import Footer from './Footer.js';


const Contact = () => {
  return (
    <div>
      <MyNavbar/>
      <ContactUs />
      <div style={{ marginTop: '5px' }}>
  <Footer />
</div>
    </div>
  )
}

export default Contact; 
