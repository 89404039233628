// Services.js

import React from 'react';
import '../CSS/Services.css';
import Services from '../Components/Services';
import MyNavbar from './Login/DemoNav';
import Footer from './Footer';

const NeedServices = () => {
  return (
    <div>
      <MyNavbar />
      <div className="dashboard mt-5">
      <h1 style={{
  color: '#000',
  fontSize: '3rem',
  textAlign: 'center',
  marginTop: '20px',
  fontWeight: 'bold',
  textTransform: 'uppercase',
  fontFamily: 'YourChosenFont, sans-serif'
}}>
  Need Services???
</h1>
        <br></br>
        <div className="gutter-background">
          <Services />
        </div>
      </div>
      <div style={{ marginTop: '5px' }}>
        {/* <Footer /> */}
      </div>
    </div>
  );
};

export default NeedServices;
