import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import Footer from './Pages/Footer';
import Services from '../src/Components/Services';
import Home from './Pages/Home';
import Contact from './Pages/Contact';
import About from './Pages/About';
import Details from './Pages/Details';
import Register from './Pages/Register';
import Login from './Pages/Login/Login';
import MyNavbar from './Pages/Login/DemoNav';

import MainPage from './Pages/Dashboard/MainPage';
import NeedServices from './Pages/NeedServices';
import NewPolicies from './Pages/NewPolicies';
import OldPolicies from './Pages/OldPolicies';
import TalktoLIC from './Pages/talktoLIC';
import PayPremium from './Pages/PayPremium';
import Success from './Pages/Sucess';
import DemoSidebar from './Pages/Dashboard/DemoSidebar';
import NewPolicyAdmin from './Pages/Dashboard/NewPolicyAdmin';
import OldPolicyAdmin from './Pages/Dashboard/OldPolicyAdmin';
import ContactAdmin from './Pages/Dashboard/ContactAdmin';
import NewAgents from './Pages/Dashboard/NewAgents';
import Gallery from './Pages/Gallery';

function App() {
  return (
    <Router>
    <div className="App">
      {/* <Navbar/> */}
      {/* <MyNavbar/> */}

      <Routes> 
        
        <Route path="/" element={<Home />} />
        <Route path="/Contact" element={<Contact />} />
        <Route path="/About" element={<About />} />
        <Route path="/NeedServices" element={<NeedServices />} />
        <Route path="/details" element={<Details />} />
        <Route path="/Register" element={<Register />} />
        <Route path="/Login" element={<Login />} />

        <Route path="/Gallery" element={<Gallery/>} />

        {/* Dashboard  */}
        <Route path="/main-page" element={<MainPage />} />
        <Route path="/demo" element={<DemoSidebar />} />

        <Route path="/new-policy-contact" element={<NewPolicyAdmin />} />
        <Route path="/old-policy-contact" element={<OldPolicyAdmin />} />
        <Route path="/admin-contact" element={<ContactAdmin />} />
        <Route path="/new-agent" element={<NewAgents />} />

        {/* Need Services */}
        <Route path="/success" element={<Success/>} />
        <Route path="/new-policies" element={<NewPolicies/>} />
        <Route path="/old-policies" element={<OldPolicies/>} />
        <Route path="/talk-to-lic" element={<TalktoLIC/>}  />
       <Route path="/pay-premium" element={<PayPremium/>} />
       
        
        </Routes>
     {/* <Footer/> */}
    </div>
    </Router>
  );
}

export default App;
