import React from 'react';
import success from '../images/success.png';

const Success = () => {
  const containerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  };

  const circleContainerStyle = {
    width: '150px',
    height: '150px',
    borderRadius: '50%',
    overflow: 'hidden',
    margin: '0 auto',
  };

  const imageStyle = {
    width: '100%',
    height: 'auto',
    borderRadius: '50%',
  };

  return (
    <div style={containerStyle}>
      <div>
        <div style={circleContainerStyle}>
          <img src={success} alt="Success Image" style={imageStyle} />
        </div>

        <h2 style={{ textAlign: 'center' }}>Form Submitted Successfully</h2>
        <h4 style={{ textAlign: 'center' }}>We will contact you soon.</h4>
      </div>
    </div>
  );
};

export default Success;
