import React from 'react'
// import '../CSS/Home.css';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import { Carousel, Container, Row, Col, Button } from 'react-bootstrap';
import docs from '../images/register/docs.jpg';
import exam from '../images/register/exam.jpg';
import license from '../images/register/license.jpg';
import pay from '../images/register/pay.jpg';
import reg from '../images/register/reg.jpg';
import train from '../images/register/train.jpg';
import '../CSS/Agent.css';

const LICAgent = () => {
    const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  return (
    <div>
      <div className="agents-steps-container">
      <h1 className="text-center">Become a LIC AGENT in 6 steps</h1>

      <Row className={`agent-row ${isMobile ? 'flex-column' : 'flex-row'}`}>
       {/* Card 1 */}
       <Col xs={12} md={4} className="agent card-shine">
          <img src={reg} alt="Card 1" className="steps-image" />
          <h1>01. Register on website</h1>
        </Col>

        {/* Card 2 */}
        <Col xs={12} md={4} className="agent card-shine">
          <img src={docs} alt="Card 2" className="steps-image" />
          <h1>02. Submit Documents</h1>
        </Col>

        {/* Card 3 */}
        <Col xs={12} md={4} className="agent card-shine">
        <img src={pay} alt="Card 2" className="steps-image" />
          <h1>03. Pay Basic Fees</h1>
        </Col>
      </Row>

      <Row className={`agent-row ${isMobile ? 'flex-column' : 'flex-row'}`}>
        {/* Card 4 */}
        <Col xs={12} md={4} className="agent card-shine">
        <img src={train} alt="Card 2" className="steps-image" />
          <h1>04. Offline/Online training for 25 Hours</h1>
        </Col>

        {/* Card 5 */}
        <Col xs={12} md={4} className="agent card-shine">
        <img src={exam} alt="Card 2" className="steps-image" />
          <h1>05. IRDA Online Exam of 50 Marks</h1>
        </Col>

        {/* Card 6 */}
        <Col xs={12} md={4} className="agent card-shine">
        <img src={license} alt="Card 2" className="steps-image" />
          <h1>06. Get your LIC Agent License!</h1>
        </Col>
      </Row>

      <div className="view-more text-right">
        <Link to="/details">View more details</Link>
      </div>
    </div>
    </div>
  )
}

export default LICAgent
