import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import { Carousel, Container, Row, Col, Button } from 'react-bootstrap';
import 'react-datepicker/dist/react-datepicker.css';
import '../CSS/Home.css';
import SIR from '../images/SIR.png';
import flexi from '../images/flexi.png';
import job from '../images/job.png';
import income from '../images/income.png';
import HomeCarousel from '../Components/Carousel';
import LICAgent from '../Components/LICAgent';
import fam from '../images/register/fam-jam.jpg';
import reg from '../images/register/reg.jpg';
import MyNavbar from './Login/DemoNav';
import Footer from './Footer';



const Home = () => {
  // Use media queries to check screen size
  // const isDesktop = useMediaQuery({ query: '(min-width: 768px)' });
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  const [isShining, setIsShining] = useState(false);

  const handleMouseEnter = () => {
    setIsShining(true);
  };

  const handleMouseLeave = () => {
    setIsShining(false);
  };

  return (
    <div>
          <MyNavbar/>

       <div style={{ paddingTop: '90px',paddingBottom:'60px' }}>
      <HomeCarousel/>
      </div>
      <div className="main-container" style={{ backgroundImage: `url(${fam})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>

      <Container className='text-col-container'>
        <Row className="head-text-container">
          <Col xs={12} md={4} lg={6} className="texts-center">
            <h1 className="heads-text-white">Want to Become a LIC Agent?</h1>
            <h5 className="descrip-text-white">Join the family of TEAM AMAZING today!!</h5>
            <Link to="/Register" className="register-button">
              <Button className='register-butts' variant="primary" size="lg">REGISTER NOW</Button>
            </Link>
          </Col>
        </Row>
      </Container>
      
    </div>
   
    <div style={{ paddingTop: '05px' }}>
    <LICAgent/>
    </div>
    
      {/* Features Container */}
      <div className="features-container">
        <h1>Key Features</h1>
        <h3> Get the benefits by joining LIC familiy today </h3>
        <Row className={`cards-row ${isMobile ? 'flex-column' : 'flex-row'}`}>
          {/* Card 1 */}
          <Col xs={12} md={4} className="card">
          <img src={job} alt="Job Reputation" />
          <Col xs={12} md={4} className='card-body'>
            <h1> Job Reputation </h1>
            <p>63 years of Treasuring the trust of Indians. LIC is most trusted insurance brand in India</p>
            </Col>
          </Col>
          {/* Card 2 */}
          <Col xs={12} md={4} className="card">
          <img src={income} alt="High Income" />
          <Col xs={12} md={4} className='card-body'>
          <h1> High Income </h1>
          <p> Earn 35% of commission on the first year premium and attractive renewal commission</p>
          </Col>
          </Col>  
          {/* Card 3 */}
          <Col xs={12} md={4} className="card">
          <img src={flexi} alt="Work Freedom" />
          <Col xs={12} md={4} className='card-body'>
             <h1 className='card-title'> Work Freedom </h1>
          <p className='card-text'> Work Part time/Full time, flexible working hours or work with existing job
          </p>
          </Col>
          </Col>
          </Row>
        </div>
      
      
        {/* Why Container */} 
        <Container fluid className="why-container">
        <h1>Why TEAM AMAZING?</h1>
      <Row>
        {/* Left Container */}
        <Col lg={6} md={12} className="left-container">
        <ul className="why-bullet-list">
            <li>Family of 100+</li>
            <li>Trusted Brand</li>
            <li>Flexible Work Schedule</li>
            <li>Unlimited Earning Potential</li>
            <li>Community Engagement</li>
            <li>Financial Stability</li>
            <li>Recognition and Awards</li>
            <li>Work-Life Balance</li>
          </ul>
        </Col>

        {/* Right Container */}
        <Col lg={6} md={12} className="right-container">
          <img src={SIR} alt="Description of the image" fluid />
        </Col>
      </Row>
    </Container>
<Footer/>
       
    </div>
  );
};

export default Home;
